import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./home.module.css";
const Home = () => {
  const navigate = useNavigate();
  const registerUser = sessionStorage.getItem("registerUser");
  const userList = JSON.parse(registerUser);
  console.log("userList", userList);

  // useEffect(() => {
  //   if (sessionStorage.getItem("info") == null) {
  //     console.log("Please Login First");
  //     navigate("/");
  //   }
  // }, []);

  // const puneUsers = userList.data.filter((user) =>
  //   user.address.toLowerCase().includes("pune")
  // );
  // const solapurUsers = userList.data.filter((user) =>
  //   user.address.toLowerCase().includes("solapur")
  // );
  // const sataraUsers = userList.data.filter((user) =>
  //   user.address.toLowerCase().includes("satara")
  // );
  // const sangliUsers = userList.data.filter((user) =>
  //   user.address.toLowerCase().includes("sangli")
  // );
  // const kolhapurUsers = userList.data.filter((user) =>
  //   user.address.toLowerCase().includes("kolhapur")
  // );

  return (
    <section className={`${style.admin_main_section}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className={`${style.homes_cards_main}`}>
              <div className={`${style.user_count_sec}`}>
                <h1 className={`${style.user_count_sec_h1}`}>User Count</h1>
                <h1 className={`${style.number_count_h1}`}>
                  {userList.data.length}
                </h1>
              </div>
              <div className={`${style.count_in_number_sec}`}>
                <Link
                  to="/register_user"
                  className={`${style.user_count_sec_button}`}
                >
                  View
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className={`${style.homes_cards_main}`}>
              <div className={`${style.user_count_sec}`}>
                <h1 className={`${style.user_count_sec_h1}`}>
                  Pune User Count
                </h1>
                <h1 className={`${style.number_count_h1}`}>
                  {puneUsers.length}
                </h1>
              </div>
              <div className={`${style.count_in_number_sec}`}>
                <Link
                  to="/register_user"
                  className={`${style.user_count_sec_button}`}
                >
                  View
                </Link>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-12 d-flex justify-content-center align-items-center mt-4">
            <div className={`${style.count_card_table}`}>
              <div className={`${style.table_top_title}`}>
                <span className={`${style.table_top_title_span}`}>
                  User Counts Of District
                </span>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className={`${style.count_table_tr}`}>
                      <th>Name Of District</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={`${style.count_table_tr}`}>
                      <td>Pune</td>
                      <td className="fw-bold">{puneUsers.length}</td>
                    </tr>
                    <tr className={`${style.count_table_tr}`}>
                      <td>Solapur</td>
                      <td className="fw-bold">{solapurUsers.length}</td>
                    </tr>
                    <tr className={`${style.count_table_tr}`}>
                      <td>Satara</td>
                      <td className="fw-bold">{sataraUsers.length}</td>
                    </tr>
                    <tr className={`${style.count_table_tr}`}>
                      <td>Sangli</td>
                      <td className="fw-bold">{sangliUsers.length}</td>
                    </tr>
                    <tr className={`${style.count_table_tr}`}>
                      <td>Kolhapur</td>
                      <td className="fw-bold">{kolhapurUsers.length}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-4">
            <div className={`${style.homes_cards_main}`}>
              <div className={`${style.user_count_sec}`}>
                <h1 className={`${style.user_count_sec_h1}`}>User Count</h1>
                <button className={`${style.user_count_sec_button}`}>
                  View
                </button>
              </div>
              <div className={`${style.count_in_number_sec}`}>
                <h1 className={`${style.number_count_h1}`}>1400</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className={`${style.homes_cards_main}`}>
              <div className={`${style.user_count_sec}`}>
                <h1 className={`${style.user_count_sec_h1}`}>User Count</h1>
                <button className={`${style.user_count_sec_button}`}>
                  View
                </button>
              </div>
              <div className={`${style.count_in_number_sec}`}>
                <h1 className={`${style.number_count_h1}`}>1400</h1>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Home;
