import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/image/t-logo.png";
import { useState, useEffect } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
function Header() {
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  // useEffect(() => {
  //   if (sessionStorage.getItem("info") == null) {
  //     console.log("Please Login First");
  //     navigate("/");
  //   }
  // }, []);

  const userDataInfo = sessionStorage.getItem("info");
  const userDetails = JSON.parse(userDataInfo);

  const handaleLogout = () => {
    sessionStorage.removeItem("info");
    navigate("/");
  };
  return (
    <Navbar collapseOnSelect expand="lg" className="bg_Color navbar-main">
      <Container fluid>
        <Link to="/home">
          <img src={logo} className="mb-2 ms-2" width={120} alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="fs_nav">
            <Nav className="me-5">
              <Nav.Link
                className={`${
                  activeLink === "registered_user" ? "active login-nav" : ""
                }`}
              >
                <Link
                  className="login-link"
                  onClick={() => handleLinkClick("career")}
                  to="/register_user"
                >
                  Registered User
                </Link>
              </Nav.Link>
              <div>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      className="nav-link active fw-bold"
                      aria-current="page"
                      to="#"
                    >
                      Welcome, {userDetails && <span> {userDetails.name}</span>}
                    </Link>
                  </li>
                  <ul className="nav-item my-account-dropdown  dropdown">
                    <DropdownButton
                      id="contact-btn"
                      className="navbar-dropdown-profile fw-bold"
                      title="My Account"
                    >
                      <Link
                        to="/"
                        className="text-start nav-drop-items text-decoration-none"
                      >
                        <li
                          onClick={handaleLogout}
                          className="ps-4 text-dark-emphasis"
                        >
                          Log Out
                        </li>
                      </Link>
                    </DropdownButton>
                  </ul>
                </ul>
              </div>
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
