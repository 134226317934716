import React, { useEffect, useState } from "react";
import "./Registered.css";
import axios from "axios";
import Helmet from "react-helmet";
import DataTable from "react-data-table-component";
import { API_BASE_URL } from "../../Constant/Global";
import Loader from "../../Component/loader/Loader";
import { Link } from "react-router-dom";

const Registered = () => {
  const [registered, setRegistered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleRegister = async () => {
    try {
      const response = await axios.get(API_BASE_URL + "admin/user_list");
      console.log("user list", response.data.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      setRegistered(response.data.data);
      if (response.data.status) {
        sessionStorage.setItem("registerUser", JSON.stringify(response.data));
      }
    } catch (error) {
      console.log("error calling api", error);
    }
  };

  useEffect(() => {
    handleRegister();
  }, []);

  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      cell: (row, index) => index + 1,
      style: { fontSize: "15px", color: "black" },
    },
    {
      name: "Full Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => `${row.first_name} ${row.middle_name} ${row.last_name}`,
      style: { fontSize: "15px", color: "black" },
    },
    {
      name: "Address",
      selector: "address",
      sortable: true,
      style: { fontSize: "15px", color: "black" },
    },
    {
      name: "Mobile",
      selector: "mobile",
      sortable: true,
      style: { fontSize: "15px", color: "black" },
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      style: { fontSize: "15px", color: "black" },
    },
    // {
    //   name: "Details",
    //   cell: (row) => (
    //     <Link to={`/user_details/${row.id}`} style={{ textAlign: "center" }}>
    //       View details
    //     </Link>
    //   ),
    // },
  ];

  const customStyles = {
    table: {
      style: {
        border: "1px solid #dddd", // Set border style for the table
        fontFamily: "Poppins",
      },
    },
    headRow: {
      style: {
        border: "1px solid #dddd", // Set border style for the header row
        fontFamily: "Poppins",
        fontWeight: "600",
      },
    },
    rows: {
      style: {
        border: "1px solid #dddd", // Set border style for the rows
        fontFamily: "Poppins",
      },
    },
  };
  return (
    <div>
      <Helmet>
        <title>Registered User | Triangulation</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h3 className="text-center user_register_title_main mt-5">
            Registered User
          </h3>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-4 mb-5">
                <DataTable
                  columns={columns}
                  data={registered}
                  pagination
                  paginationPerPage={50}
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Registered;
