import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { API_BASE_URL } from "../../Constant/Global";
const Login = () => {
  //   const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [response, setResponse] = useState("");

  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    var password = "123456";
    const newErrors = {
      email: "",
      password: "",
    };

    // Email validation (required and valid email format)
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      valid = false;
    }

    // Name validation (required field)
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
      valid = false;
    } else if (formData.password !== password) {
      newErrors.password = "Enter Correct password";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (validateForm()) {
    //   const { email, password } = formData;
    //   console.log("login finalData", formData);
    //   try {
    //     const finalData = { email: email, password: password };
    //     const res = await axios.post(API_BASE_URL + "admin/login", finalData);
    //     console.log(res.data.data);
    //     if (res.data.status) {
    //       sessionStorage.setItem("info", JSON.stringify(res.data.data));
    //       navigate("/home");
    //     } else {
    //       alert(res.data.status);
    //       setResponse(res.data.error);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    if (!validateForm()) {
      return;
    }
    const correctEmail = "admin@gmail.com";
    const correctPassword = "123456";

    if (
      formData.email === correctEmail &&
      formData.password === correctPassword
    ) {
      navigate("/register_user");
    } else {
      setResponse("Invalid email or password");
    }
  };

  const handleShowPassword = () => {
    setPasswordShow(!passwordShow);
  };

  return (
    <>
      <Helmet>
        <title>Login | Staff Management Software</title>
      </Helmet>
      <section className="login-main">
        <div className="container">
          <div className="row login-sub-sec">
            <div className="col-lg-6 login-left-col">
              <div className="login-title-sec">
                <h1 className="login-title-left">Admin Triangulation</h1>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="card login-card">
                <div className="card-body h-custom-2 d-flex justify-content-center align-items-center">
                  <form style={{ width: "23rem" }} onSubmit={handleSubmit}>
                    <h3 className="mb-3 login-right-title pb-3">Log in</h3>
                    {response && (
                      <div className="alert alert-danger alert-dismissible fade show">
                        <strong>Alert!</strong> {response}
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                        ></button>
                      </div>
                    )}

                    <div className="form-outline mb-4">
                      <label
                        className="form-label login_label"
                        htmlFor="form2Example18"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        id="form2Example18"
                        className="login-input"
                      />

                      {errors.email && (
                        <div className="error-text">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-outline password_sec mb-4">
                      <label
                        className="form-label login_label"
                        htmlFor="form2Example28"
                      >
                        Password
                      </label>
                      <input
                        type={passwordShow ? "password" : "text"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        id="form2Example28"
                        className="login-input"
                      />
                      <div className="password_icon_sec">
                        {passwordShow ? (
                          <>
                            <IoEyeOutline onClick={handleShowPassword} />
                          </>
                        ) : (
                          <IoEyeOffOutline onClick={handleShowPassword} />
                        )}
                      </div>
                      {errors.password && (
                        <div className="error-text">{errors.password}</div>
                      )}
                    </div>
                    <div className="pt-1 mb-4">
                      <button className="btn login-btn" type="submit">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
